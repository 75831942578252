import React from 'react'

function ArrowLeft() {
  return (
    <svg className="w-full h-full fill-current" viewBox="0 0 12.5 11.1">
      <path d="M12.5 4.8H2.3l3.8-3.7L5 0 0 5l.5.5-.5.6 5 5L6.1 10 2.3 6.3h10.2z" />
    </svg>
  )
}

export default ArrowLeft
