import React from 'react'

function ArrowRight() {
  return (
    <svg className="w-full h-full fill-current" viewBox="0 0 12.5 11.1">
      <path d="M12.5 5l-5-5-1 1.1 3.7 3.7H0v1.5h10.2L6.5 10l1 1.1 5-5-.5-.6z" />
    </svg>
  )
}

export default ArrowRight
