import React from 'react'
import PropTypes from 'prop-types'
import Image from 'gatsby-image'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import Fade from 'react-reveal/Fade'

import Layout from '../components/Layout'
import ArrowLeft from '../components/SVG/ArrowLeft'
import ArrowRight from '../components/SVG/ArrowRight'

const newsArticle = ({ pageContext, location }) => {
  const { data: pageData, next, previous } = pageContext

  return (
    <Layout
      seoTitle={pageData.meta_title || pageData.title}
      seoDescription={pageData.meta_description}
      location={location}
    >
      <div className="px-ogs">
        <div className="wrapper pt-32 md:pt-40 md:pb-26">
          <div className="text-center mb-8 md:mb-19">
            <Fade ssrReveal delay={250} duration={2250}>
              <AniLink
                fade
                to={`/news`}
                className="inline-flex items-baseline text-sm uppercase"
              >
                <div className="w-3 mt-px mr-2">
                  <ArrowLeft />
                </div>
                Back to News
              </AniLink>
            </Fade>
          </div>
          {pageData.title && (
            <Fade
              ssrReveal
              delay={250}
              duration={2250}
              bottom
              distance={'25px'}
            >
              <h1 className="text-xl lg:text-2xl font-condensed uppercase tracking-wider text-center mb-14 md:mb-30">
                {pageData.title}
              </h1>
            </Fade>
          )}
          <Fade ssrReveal delay={250} duration={2250}>
            <div className="md:flex md:space-x-gs">
              <div className="w-full md:w-1/2 mb-16 md:mb-30">
                {pageData.image.fluid && (
                  <div className="md:w-5/6">
                    <Image
                      fluid={{
                        ...pageData.image.fluid,
                        aspectRatio: 1,
                      }}
                    />
                  </div>
                )}
              </div>
              {pageData.body.html && (
                <div className="w-full md:w-1/2 mb-14 md:mb-30">
                  <div
                    className="rte"
                    dangerouslySetInnerHTML={{
                      __html: pageData.body.html,
                    }}
                  />
                </div>
              )}
            </div>
            <div className="flex">
              <div className="w-1/2 flex">
                {previous && (
                  <AniLink
                    fade
                    to={`/news/${previous.uid}`}
                    className="inline-flex items-baseline text-sm uppercase leading-none"
                  >
                    <div className="w-3 mr-2">
                      <ArrowLeft />
                    </div>
                    Prev
                  </AniLink>
                )}
              </div>
              <div className="w-1/2 flex justify-end">
                {next && (
                  <AniLink
                    fade
                    to={`/news/${next.uid}`}
                    className="inline-flex items-baseline text-sm uppercase leading-none"
                  >
                    Next
                    <div className="w-3 ml-2">
                      <ArrowRight />
                    </div>
                  </AniLink>
                )}
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </Layout>
  )
}

newsArticle.propTypes = {
  location: PropTypes.object.isRequired,
}

export default newsArticle
